import styled from 'styled-components';
import image from "../../../../../images/AdobeStock_ing06.jpeg";

export const Styledp = styled.p`
color: #000;
`
export const StyledC = styled.div`
width: 70%;
margin: auto;
background-color: white;
padding: 20px;
`
export const StyledBody = styled.div`
  height: 100vh;
  background-image: url(${image });
  background-size: cover;
  display: flex;

`;