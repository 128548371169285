import { useNavigate } from "react-router";
import Background from "./botao-voltar.png";
import StyledLogo, { Whats } from "./style";

const Logo = () => {

    const navigate = useNavigate();

  return (
    <StyledLogo onClick={() => navigate("/")}>
      <Whats src={Background} alt="logo" />
    </StyledLogo>
  );
};
export default Logo;
