import styled from "styled-components";
import image from "../../images/AdobeStock_img04.jpeg";


export const StyledBody = styled.div`

  background-image: url(${image })

`;

export const StyledButton = styled.button`
background-color: red;
width: 125px;
height: 50px;
border-radius: 15px;
margin: 20px 20px 0 0;
`
export const StyledHeader = styled.div`
display: flex;
width: 100%;
flex-direction: row-reverse;
justify-content: space-between;
`

export const StyledDiv = styled.div`
display: flex;
flex-wrap: wrap;
`

