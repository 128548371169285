import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import "../style.css";

function Human({end}) {
  const navigate = useNavigate()
  return (
    <Dropdown drop={end}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Human
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => navigate("/signup")} >Tokenn Care</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Tokenn Child</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Doações</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
  
  export default Human;