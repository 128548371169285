import Logo from "../../../../backButton";
import { StyledC, Styledbody } from "./style";

function Private() {

    return (<Styledbody>
        <StyledC>
<p>Política de Privacidade</p>

<p>Nós, da TOKENN TECNOLOGIA E SOLUÇÕES DE PAGAMENTOS denominada neste documento como TOKENN (CNPJ: XXXXXXXX/XXXX-XX), estamos comprometidos com a sua privacidade. O objetivo deste documento é esclarecer quais informações são coletadas dos usuários no site www.tokenn.us, de forma que esses dados sejam manipulados e utilizados por nós.</p>

<p>Nesse sentido, a presente Política de Privacidade explica como as suas informações e dados serão coletados, utilizados, compartilhados e armazenados por meio dos nossos sites e respectivos serviços.</p>

<p>Qualquer dúvida em relação à nossa política de privacidade pode ser esclarecida entrando em contato conosco. Envie um e-mail para global@tokenn.us</p>

<p>Estamos localizados na Av. Pedro Blanco da Silva, Sala 04, Jardim Paulista, Jundiaí/SP.</p>

<p>O usuário responderá pela veracidade dos dados fornecidos, sob pena de se sujeitar às sanções legais cabíveis.</p>

<p>QUAIS DADOS E COMO SÃO COLETADOS</p>

<p>Dados fornecidos pelo usuário: São fornecidos os dados de identificação pessoal: nome, e-mail e empresa em que trabalha.</p>

<p>Como são coletados os dados?</p>

<p>Os dados são coletados via preenchimento voluntário dos usuários dos formulários de inscrição dos materiais, eventos e toda e qualquer interação dentro do domínio www.tokenn.us</p>

<p>Como são coletados os dados pela navegação e cookies?</p>

<p>São coletadas informações como endereço IP, localização geográfica, origem do tráfego, fonte de referência, tipo de navegador, duração da visita e páginas visitadas.</p>

<p>Como são coletados dados de navegação no site?</p>

<p>Quando o usuário visita nosso site, é inserido um “cookie” no seu navegador que utilizamos para identificar quantas vezes você retorna ao nosso endereço, bem como mapeamento de toda navegação.</p>

<p>Eventualmente, a solicitação de algumas informações adicionais poderá ser feita por meio de contato direto com os usuários via e-mail.</p>

<p>SOBRE O USO E TRATAMENTO DOS DADOS</p>

<p>Finalidade do uso dos dados: A TOKENN utiliza os dados fornecidos por você, capturados pelos cookies gerados a partir da sua navegação e dados capturados pelos formulários para a prestação e aprimoramento dos serviços que oferecemos a você, bem como divulgação e publicidade em mídias de qualquer natureza. Pelo aceite dessa Política de Privacidade, o usuário autoriza o recebimento de comunicações.</p>

<p>Como os dados são usados?</p>

<p>Seu e-mail é utilizado para o envio do material, informação e comunicação institucional requisitado por você. Também pode ser usado para envio de Newsletters, comunicação de eventos, notificações, materiais gratuitos ou de novos produtos da TOKENN e parceiros. O usuário pode cancelar a assinatura a qualquer momento diretamente pelo link no e-mail. Utilizaremos os dados capturados pela navegação para monitorar as ações dos visitantes às páginas, criar listas dinâmicas dessas pessoas para execução de ações de marketing direcionadas e personalizar o serviço para se adequar cada vez mais aos seus gostos e interesses.</p>

<p>Compartilhamento com terceiros</p>

<p>Os dados recebidos pela TOKENN não serão compartilhados com terceiros sem prévia autorização. Os dados recebidos pela TOKENN podem ser anonimizados em formato estatístico, que não individualize o usuário, para estudos internos ou compartilhamento com os parceiros.</p>

<p>Cancelamento da autorização</p>

<p>Caso o usuário não concorde com o recebimento de comunicações, poderá cancelar a qualquer momento via link no e-mail ou pode enviar um e-mail para: global@tokenn.us</p>

<p>ARMAZENAMENTO E ACESSOS</p>

<p>Armazenamento e acesso: Os dados recebidos pela TOKENN são armazenados sob práticas de segurança de informação, por prazo indeterminado, com acesso restrito somente às pessoas cuja atividade é necessário o acesso, às quais tem o dever de confidencialidade e proteção dos dados, conforme legislação e esta política.</p>


<p>Colaboradores</p>

<p>Colaboradores da TOKENN poderão entrar em contato via e-mail para realizar suporte, apresentar produtos, serviços, promoções, notícias, atualizações, eventos e realizar pesquisas de interesse e satisfação.</p>

<p>DISPOSIÇÕES GERAIS</p>

<p>Devolução e exclusão</p>

<p>Sempre que solicitado, a qualquer tempo a TOKENN devolverá todos os documentos, registros e cópias que contenham quaisquer dados pessoais a que tenha tido acesso em virtude do presente Contrato, bem como excluirá da sua base de dados quando solicitados.</p>

<p>Uso diversos</p>

<p>Eventualmente, poderemos utilizar os dados para finalidades não previstas nesta política de privacidade, mas estas estarão dentro das suas legítimas expectativas. O eventual uso dos seus dados para finalidades que não cumpram com essa prerrogativa será feito mediante autorização prévia.</p>

<p>Responsabilidade</p>

<p>O usuário deve estar ciente que a TOKENN não será responsável por uma eventual quebra de segurança da base de dados que cause a divulgação ou acesso indevido de dados dos usuários. Em tal caso, nenhuma compensação por parte da TOKENN será devida ao usuário.</p>

<p>Atualizações</p>

<p>Este documento se mantém atualizado para toda ação a ser realizada pelo usuário que deverá tomar ciência e aceitar. A versão atualizada desta Política de Privacidade sempre estará disponível para consulta.</p>

<p>Última atualização desta Política de Privacidade disponibilizada em 29 de Julho de 2022.</p>
</StyledC>
<Logo />
    </Styledbody>
    )
    }
    
    export default Private;