import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import "../style.css";

function Bank({end}) {
  const navigate = useNavigate();
    return (
      <Dropdown drop={end} >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Bank & Trust
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => navigate("/signup")} >Portal do investidor</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Private Equity</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Hedge Funds</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Assets</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Escrow Account</Dropdown.Item>
        <Dropdown.Item onClick={() => window.location.href='https://banklabs.com.br'}>Banco Digital</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Meios de Pagamentos</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Risk & Compliance</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Auditoria Independente</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Análise de Risco</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Créditos Privados</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >CCBs</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Seguros</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Research</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    );
  }
  
  export default Bank;