import AdminComponent from "./admin";
import DashboardComponent from "./dashboard";
import GetInTouchComponent from "./Getin";
import HomeComponent from "./Home";
import LoginComponent from "./Login";
import SignupComponent from "./signup";
import WithdrawComponent from "./withdraw";

export const Home = HomeComponent;
export const Login = LoginComponent;
export const Signup = SignupComponent;
export const Dashboard = DashboardComponent;
export const Admin = AdminComponent;
export const GetInTouch = GetInTouchComponent;
export const Withdraw = WithdrawComponent;
