import styled from "styled-components";
import image from "../../images/AdobeStock_img04.jpeg";

export const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

`;

export const StyledBody = styled.div`
  height: 100vh;
  background-image: url(${image });
  background-size: cover;

`;

export const Wallet = styled.div`
  display: flex;
  width: 400px;
  height: 250px;
  flex-direction: row;
  background-color: #efefef;
  border-radius: 20px;

`;

export const StyledItem = styled.div`
text-align: center;
padding-top: 5px;
`;

export const StyledButton = styled.button`
  width: 65px;
  margin: 3px;
`;


export const StyledImg = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 20px;
  margin: 2px;
`;

export const StyledData = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
width: 100%;
`;