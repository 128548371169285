import "./style";
import {
    StyledButton,
    StyledButtons,
    StyledContainer,
    StyledContent,
    StyledDivButton,
    StyledEmptyGrid,
    StyledFlex, StyledFlexHeader,
    StyledFlexImage,
    StyledFooter,
    StyledFooterDiv,
    StyledGrid,
    StyledH5,
    StyledH6,
    StyledHeader,
    StyledImgLogo,
    StyledInfo,
    StyledInsideContainer,
    StyledLayout,
    StyledLogo, StyledMain, StyledRoot, StyledSpan, StyledSvg
} from "./style";

import Logo from "../../../../backButton";


function Cambio() {

return (
    <StyledRoot>
        <StyledLayout>
            <StyledFlex>
                <StyledMain>
                    <StyledHeader>
                        <StyledFlexHeader>
                            <StyledLogo>
                                <StyledFlexImage>
                                    <StyledImgLogo src="https://onboarding.ourinvest.com.br/assets/images/logos/logo2.svg" />
                                </StyledFlexImage>
                            </StyledLogo>
                        </StyledFlexHeader>
                    </StyledHeader>

                    <StyledContent>
                        <StyledGrid>
                            <StyledEmptyGrid />
                            <StyledInfo>
                                <StyledContainer>
                                    <StyledInsideContainer>
                                        <StyledH6><strong>Abra sua conta câmbio</strong></StyledH6>
                                        <StyledH5><strong>Qual tipo de conta você deseja abrir?</strong></StyledH5>
                                        <StyledButtons>
                                            <StyledDivButton>
                                                <StyledButton onClick={() => window.location.href = 'https://onboarding.ourinvest.com.br/?correspondente=2291&form=person'}>
                                                    <StyledSpan><StyledSvg><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></StyledSvg></StyledSpan>
                                                Pessoa física
                                                </StyledButton>
                                            </StyledDivButton>
                                            <StyledDivButton>
                                                <StyledButton onClick={() => window.location.href = 'https://onboarding.ourinvest.com.br/?correspondente=2291&form=business'}>
                                                    <StyledSpan><StyledSvg><path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"></path></StyledSvg></StyledSpan>
                                                    Pessoa jurídica
                                                </StyledButton>
                                            </StyledDivButton>
                                        </StyledButtons>
                                    </StyledInsideContainer>
                                </StyledContainer>
                            </StyledInfo>
                            <StyledEmptyGrid />
                        </StyledGrid>
                    </StyledContent>

                    <StyledFooter>
                        <StyledFooterDiv>
                        Ourinvest @
                        2022
                        - Todos os direitos reservados
                        </StyledFooterDiv>
                    </StyledFooter>

                </StyledMain>
            </StyledFlex>
        </StyledLayout>
        <Logo />
    </StyledRoot>
)
}

export default Cambio;