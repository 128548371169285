import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import "../style.css";

function Service({end}) {
  const navigate = useNavigate()
  return (
    <Dropdown drop={end}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Atendimento
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => navigate("/getintouch")}>Fale Conosco</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/getintouch")}>Gente & Gestão</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/getintouch")}>Canal de Denúncia</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/getintouch")}>Tokenn Partner</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
  
  export default Service;