import styled from "styled-components";

export const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  height: 540px;
  bottom: 0px;
  flex-direction: column;
  align-items: center;
`;

export const StyledNameField = styled.div`
  width: 580px;
  height: 78px;
  color: #06152b;

  @media screen and (max-width: 720px) {
    width: 80%;
  }
`;

export const StyledMessageField = styled.div`
  margin-top: 20px;
  width: 580px;
  height: 178px;
  color: #06152b;

  @media screen and (max-width: 720px) {
    width: 80%;
    font-size: 10px;
  }
`;
export const StyledFlexDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const StyledMailField = styled.div`
  width: 580px;
  height: 78px;
  color: #06152b;
  display: flex;

  @media screen and (max-width: 720px) {
    width: 80%;
  }
`;
export const StyledNameInput = styled.input`
  width: 100%;
  height: 50px;
  border: 1px solid #06152b;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;
  @media screen and (max-width: 720px) {
    font-size: 10px;
  }
`;
export const StyledMessageInput = styled.textarea`
  width: 100%;
  height: 150px;
  border: 1px solid #06152b;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 5px;

  @media screen and (max-width: 720px) {
    font-size: 10px;
  }
`;
export const StyledMailInput = styled.input`
  width: 90%;
  height: 50px;
  border: 1px solid #06152b;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;

  @media screen and (max-width: 720px) {
    font-size: 10px;
  }
`;

export const StyledFoneInput = styled.input`
  width: 100%;
  height: 50px;
  border: 1px solid #06152b;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;
  @media screen and (max-width: 720px) {
    width: 100%;
    font-size: 10px;
  }
`;

export const StyledButton = styled.button`
  width: 580px;
  height: 52px;
  background: #46bd62;
  border-radius: 4px;
  margin-top: 40px;
  color: #fff;
  border: none;
  @media screen and (max-width: 720px) {
    width: 80%;
  }
`;
