import Logo from "../../../../backButton";
import { StyledBody, StyledC, Styledp } from "./style";

function Intro() {

return (<StyledBody>
    <StyledC>
<Styledp>Prazer, somos a TOKENN !</Styledp>

<Styledp>Empresa de tecnologia que traz em seu DNA soluções inteligentes para pessoas e empresas. Somos apaixonados por tecnologia, e não menos importante por resultados e impactos positivos e relevantes.</Styledp>

<Styledp>Nossas soluções são lideradas por profissionais experientes e uma equipe altamente qualificada. Aqui seu projeto será guiado pelas principais tendências do mercado, atrelando as melhores soluções tecnológicas e financeiras do Brasil e do mundo.</Styledp>

<Styledp>Chegamos em 2022. Na bagagem, trouxemos grande experiência e as principais tendências tecnológicas e financeiras do país.</Styledp>

<Styledp>Fale conosco.<br />
comercial@tokenn.us</Styledp>

<Styledp>www.tokenn.us</Styledp>
</StyledC>
<Logo />
</StyledBody>
)
}

export default Intro;