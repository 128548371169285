import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logout } from '../../Providers/UserSlice';
import api from "../../services/api";
import UserEdit from "./UserEdit";
import { StyledButton, StyledDiv, StyledHeader } from "./style";

const AdminComponent = () => {
  const navigate = useNavigate();

  let token = useSelector((state) => state).user.Token
  const [usuarios, setUsuarios] = useState();
  const [filtro, setFiltro] = useState();

  const dispatch = useDispatch()

  function Logout() {
    dispatch(logout())
    navigate("/Login")
  }

  function handleEvent(e) {
    setFiltro(e.target.value);
  }

  function handleFilter() {
    setUsuarios([])
    api
        .get(`users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsuarios(response.data.filter((item) => item.name.toLowerCase().includes(filtro.toLowerCase())? true: item.cnpj.toLowerCase().includes(filtro.toLowerCase())   )  );
      })
      .catch((e) => console.log(e));

  }

  function Refresh() {
    window.location.reload();
  }


  useEffect(() => {

    token = localStorage.getItem("Token");
    const user_id = token ? jwt_decode(token) : false;

    if(!token) navigate("/Login")

    api
    .get(`users/${user_id.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if(response.data.permission !== 2) navigate("/dashboard")
        api
        .get(`users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((e) => console.log(e));


      })
      .catch((e) => console.log(e));

}, [token]);

  return (<>
  <StyledHeader>
    <StyledButton onClick={() => Logout()}>Sair</StyledButton>
    <div>
    <input onChange={(e) => handleEvent(e)}></input>
    <button onClick={() => handleFilter()}>filtrar</button>
    </div>
  </StyledHeader>
  <StyledDiv>
    { usuarios && 
        usuarios.map((value,key)=>
        <UserEdit user={value} Refresh={Refresh} />
        )
    }
    </StyledDiv>
    </>
  );
};

export default AdminComponent;