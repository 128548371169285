import AboutUs from './AboutUs';
import Academy from './Academy';
import Bank from './Bank';
import Content from './Content';
import Exchange from './Exchange';
import Human from './Human';
import Menu from './Menu';
import Service from './Service';
import Tech from './Tech';

import { StyledContainer, StyledMobileContainer } from './style';

function Footer() {
  return (<>
    <StyledContainer>
        <AboutUs />
        <Exchange />
        <Tech />
        <Bank />
        <Human />
        <Academy /> 
        <Content />
        <Service />
    </StyledContainer>
    <StyledMobileContainer>
      <Menu />
    </StyledMobileContainer>
    </>
  );
}

export default Footer;