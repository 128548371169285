import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as yup from "yup";
import { login } from '../../Providers/UserSlice';
import api from "../../services/api";
import Logo from "../backButton";
import {
  Box, Container,
  Header, Input,
  LoginButton,
  LoginFormContainer, StyledBody,
  StyledText,
  TextInput
} from "./style";

const LoginComponent = ({ userType, notifyLogin, notifyLoginArea }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  
  const schema = yup.object().shape({
    mail: yup.string().required("Campo Obrigatório"),
    password: yup
      .string()
      .required("Campo Obrigatório")
      .min(4, "Mínimo de 4 caracteres"),
  });

  const notifyError = () =>
  toast.error("Email e/ou senha inválidos!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const redirectToDashboard = () => {
    navigate("/dashboard")
  };

  const onSubmit = (data) => {
    api
      .post("/users/login", data)
      .then((response) => {
        dispatch(login(response.data.token))
        redirectToDashboard();
      })
      .catch((error) => {
        alert("Dados incorretos!")
      });
  };

  return (
    <StyledBody>
    <LoginFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Header>Login</Header>
          <Box>
            <TextInput error={!!errors.mail}>Email:</TextInput>
            <Input {...register("mail")} error={!!errors.mail} />
            <p>{errors.mail?.message}</p>
          </Box>
          <Box>
            <TextInput error={!!errors.password}>Senha:</TextInput>
            <Input
              {...register("password")}
              type="password"
              error={!!errors.password}
            />
            <p>{errors.password?.message}</p>
          </Box>
          <StyledText>Não tem uma conta? <span onClick={() => navigate("/signup")}>Crie uma!</span></StyledText>
          <LoginButton type="submit">Entrar</LoginButton>
        </Container>
      </form>
    </LoginFormContainer>
    <Logo />
    </StyledBody>
  );
};

export default LoginComponent;

