import { useNavigate } from "react-router";
import logo from "../../../images/logo_01.png";
import Footer from "../Footer";
import { StyledButton, StyledDiv, StyledLogo, StyledTokenn } from './style';

function Header() {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate("/Login")
  };
  const redirectToSignup = () => {
    navigate("/Signup")
  };

  return (
    <StyledDiv>
      <StyledTokenn src={logo} />
      <Footer />
      <StyledLogo>
        <StyledButton onClick={() => redirectToLogin()}>Login</StyledButton>
        <StyledButton onClick={() => redirectToSignup()}>Seja Membro</StyledButton>
      </StyledLogo>
    </StyledDiv>
  );
}

export default Header;