import Carousel from 'react-bootstrap/Carousel';
import Partner1 from "../../../images/Mater_Market.png";
import Partner4 from "../../../images/WTB1_icon.png";
import Partner6 from "../../../images/index.png";
import Partner5 from "../../../images/logo_01.png";
import Partner2 from "../../../images/logo_banklabs_final.png";
import Partner3 from "../../../images/straittrust.png";
import "./style.css";

function CarouselComponent() {
  return (
    <Carousel>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src={Partner5}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src={Partner1}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img
          className="d-block w-100"
          src={Partner2}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Partner3}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Partner4}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Partner6}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselComponent;