import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import image1 from "../../images/_avatar.jpg";
import { logout } from '../../Providers/UserSlice';
import api from "../../services/api";
import { Styled, StyledBody, StyledButton, StyledData, StyledImg, StyledItem, Wallet } from "./style";

const DashboardComponent = () => {
  const navigate = useNavigate();

  let token = useSelector((state) => state).user.Token
  const [usuario, setUsuario] = useState();

  const dispatch = useDispatch()

  function Logout() {
    dispatch(logout())
    navigate("/Login")
  }

  useEffect(() => {

    token = localStorage.getItem("Token");

    const user_id = token ? jwt_decode(token) : false;

    if(!token) navigate("/")

    api
      .get(`users/${user_id.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if(response.data.permission === 2) navigate("/Admin")
        if(response.data.money !== null) response.data.money = response.data.money.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        setUsuario(response.data);
      })
      .catch((e) => console.log(e));
}, [token]);

  return (<>
    { usuario && 
    <StyledBody>
    <Styled>
    <Wallet>
        <div>
        <StyledImg src={image1} />
        <StyledItem>{usuario.mail}</StyledItem>
        <StyledItem>{usuario.phone}</StyledItem>
        </div>
        <StyledData>
          <div>
            <StyledItem>{usuario.name}</StyledItem>
            <StyledItem>{usuario.cnpj}</StyledItem>
          </div>
          <div>{usuario.money}</div>
          <div>
          {usuario.permission === 1 && <StyledButton onClick={() => navigate("/withdraw")}>resgate</StyledButton>}
          <StyledButton onClick={() => Logout()}>sair</StyledButton>
          </div>
          <div onClick={() => navigate("/getintouch")}>Entre em contato!</div>
        </StyledData>
      </Wallet>
    </Styled>
    </StyledBody>
    }
    </>
  );
};

export default DashboardComponent;