import styled from "styled-components";

export const StyledFooter = styled.div`
bottom: 0;
left: 0;
height: 60px;
position: fixed;
width: 100%;
font-size: 14px;
text-align: left;
color: black;
display: flex;
background-color: white;
justify-content: space-around;

@media (max-width: 400px) {
justify-content: space-between;
}


`;

export const StyledItem = styled.div`
width: 33%;
text-align: center;
margin: auto;

@media (max-width: 400px) {
margin: auto 0;
}

`;


export const StyledemptyItem = styled.div`
width: 33%;
text-align: center;
margin: auto;

@media (max-width: 400px) {
    display: none;
  }
`;


export const StyledItemLogo = styled.div`
display: flex;
flex-direction: row-reverse;
width: 33%;
padding-right: 20px;

@media (max-width: 400px) {
  padding-right: 0;
}


`;

export const StyledSpan = styled.span`
margin: auto 0;
font-size: 12px;

`;

export const StyledImg = styled.img`
width:60px;
height: 60px;

`;

export const StyledDiv = styled.div`
@media (max-width: 800px) {
  display: none;
}
`;