import styled from "styled-components";

const StyledLogo = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
`;

export const Whats = styled.img`
background-size: cover;
width: 100%;
height: 100%;
`;

export default StyledLogo;
