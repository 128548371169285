import mail from "../../images/_avatar.jpg";
import Logo from "../backButton";
import Form from "./form";
import {
  StyledBlock, StyledContainer,
  StyledForm,
  StyledHeader, StyledText, StyledVector
} from "./style";
  
  const GetInTouchComponent = () => {
    return (
      <StyledContainer>
        <StyledForm>
          <StyledHeader>
            <StyledBlock>
              <StyledVector src={mail}></StyledVector>
            </StyledBlock>
            <StyledText>
              Entre em
              <br /> <b>Contato</b>
            </StyledText>
          </StyledHeader>
          <Form />
        </StyledForm>
        <Logo />
      </StyledContainer>
    );
  };
  
  export default GetInTouchComponent;
  