import styled from "styled-components";

export const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 721px;
  display: flex;
  justify-content: center;
`;

export const StyledForm = styled.div`
  position: absolute;
  width: 700px;
  height: 721px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(6, 21, 43, 0.08);
  border-radius: 4px;
  @media screen and (max-width: 720px) {
    width: 80%;
  }
`;

export const StyledHeader = styled.div`
  width: 250px;
  height: 60px;
  margin-top: 72px;
  display: flex;
  background-color: #fff;
`;
export const StyledBlock = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledText = styled.div`
  width: 166px;
  height: 58px;
  display: flex;
  flex-direction: column;
  color: #06152b;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  justify-content: center;
`;

export const StyledVector = styled.img`
  width: 50px;
  height: 50px;
`;
