import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router";
import "../style.css";

function AboutUs({end}) {
  const navigate = useNavigate();
  return (
    <Dropdown drop={end}>
    <Dropdown.Toggle variant="success" id="dropdown-basic">
      Sobre Nós
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => navigate("/aboutus")} >Quem somos</Dropdown.Item>
      <Dropdown.Item onClick={() => navigate("/signup")} >Trabalhe Conosco</Dropdown.Item>
      <Dropdown.Item onClick={() => navigate("/security")} >Politica de Segurança e Privcidade</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>

  );
}

export default AboutUs;