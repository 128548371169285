import styled from 'styled-components';
import image from "../../../../../images/AdobeStock_ing06.jpeg";


export const Styledbody = styled.div`
  height: 100%;
  background-image: url(${image });
  background-size: cover;
  display: flex;

`;

export const StyledC = styled.div`
width: 70%;
margin: auto;
background-color: white;
padding: 20px;
`