import { yupResolver } from "@hookform/resolvers/yup";
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import api from "../../services/api";
import Logo from "../backButton";
import {
  Box, Container,
  Header, Input,
  LoginButton,
  LoginFormContainer,
  SelectInput,
  StyledBody,
  TextInput
} from "./style";

const SignupComponent = ({ userType, notifyError, notifyLogin, notifyLoginArea }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [produto, setProduto] = useState("Exchange");
  const [category, setCategory] = useState("Exchange");
  const [categories, setCategories] = useState(["Câmbio", "Hedge", "Operações Estruturadas", "Remessas Internacionais", "CDE", "Proteção Cambial", "Transfêrencias Internacionais", "Investimentos"]);
  let produtos = [ "Exchange", "Tech" , "Bank & Trust", "Human", "Academy", "conteúdos"]

  const schema = yup.object().shape({
    mail: yup.string().email().required("Campo Obrigatório"),
    name: yup.string().required("Campo Obrigatório"),
    cnpj: yup.string().test(
      'test-invalid-cpf',
      'cpf/cnpj inválido',
      (Cnpj) =>  cnpj.isValid(Cnpj) || cpf.isValid(Cnpj)).required("Campo Obrigatório"),
    phone: yup.string().required("Campo Obrigatório"),
    password: yup
      .string()
      .required("Campo Obrigatório")
      .min(6, "Mínimo de 6 caracteres"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const redirectToDashboard = () => {
    navigate("/login")
  };

  function handleChangeProduct (event) {
    setProduto(event.target.value)

  switch (event.target.value) {
  case 'Exchange':
    setCategories(["Câmbio", "Hedge", "Operações Estruturadas", "Remessas Internacionais", "CDE", "Proteção Cambial", "Transfêrencias Internacionais", "Investimentos"])
    setProduto("Câmbio");
    break;
  case "Tech":
    setCategories(["E-Commerce", "MarketPlace", "BlockChain", "Tokenização", "Proteção de dados", "DPO as a Service", "Bank as a Service"]);
    setProduto("E-Commerce");
    break;
  case 'Bank & Trust':
    setCategories(["Portal do investidor", "Private Equity", "Hedge Funds", "Assets", "Escrow Account", "Banco digital", "Meios de Pagamento",
    "Risk & Compliance", "Auditoria Independente", "Análise de Risco", "Creditos Privados", "CCBs", "Seguros", "Research"]);
    setProduto("Portal do investidor")
    break;
  case 'Human':
    setCategories(["Tokenn Care", "Tokenn Child", "Doaçóes"]);
    setProduto("Tokenn Care")
    break;
  case 'Academy':
    setCategories(["Aprenda a Investir no Exterior", "Ativos Digitais", "Introdução ao LGPD", "BlockChain"]);
    setProduto("Aprenda a Investir no Exterior")
    break;
  case 'conteúdos':
    setCategories(["Token Talks", "Palestras", "Blog", "Tokenn Ideals", "Proteção de dados", "DPO as a Service", "Bank as a Service"]);
    setProduto("Token Talks")
    break;
  default:
    console.log("");
  }
}

function handleChangeCategory (event) {
  setCategory(event.target.value)
}

  const onSubmit = (data) => {
    console.log(data)
    let mail = {
      name: data.name,
      email: data.mail,
      telephone: data.phone,
      message: category, produto,
    };

    api
      .post(`/messages/`, mail)
      .catch((e) => e.messages);

    api
      .post("/users/signup", data)
      .then((response) => {
        redirectToDashboard();
      })
      .catch((error) => {
        alert("Dados incorretos!")
      });
  };

  return (
    <StyledBody>
    <LoginFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Header>Cadastro</Header>
          <Box>
            <TextInput error={!!errors.mail}>Nome/Razão Social:</TextInput>
            <Input {...register("name")} error={!!errors.name} />
            <p>{errors.mail?.message}</p>
          </Box>
          <Box>
            <TextInput error={!!errors.cnpj}>CPF/CNPJ:</TextInput>
            <Input {...register("cnpj")} error={!!errors.cnpj} />
            <p>{errors.cnpj?.message}</p>
          </Box>
          <Box>
            <TextInput error={!!errors.mail}>Email:</TextInput>
            <Input {...register("mail")} error={!!errors.mail} />
            <p>{errors.mail?.message}</p>
          </Box>
          <Box>
            <TextInput error={!!errors.phone}>Telefone:</TextInput>
            <Input {...register("phone")} error={!!errors.phone} />
            <p>{errors.phone?.message}</p>
          </Box>
          <Box>
          <TextInput >Categoria de produto:</TextInput>
          <SelectInput onChange={handleChangeProduct}>
            {produtos.map((key, index) => <option value={key}>{key}</option> )}
          </SelectInput>
          </Box>
          <Box>
            <TextInput >Serviço:</TextInput>
            <SelectInput onChange={handleChangeCategory}>
            {categories.map((key, index) => <option value={key}>{key}</option> )}
          </SelectInput>
          </Box>
          <Box>
            <TextInput error={!!errors.password}>Senha:</TextInput>
            <Input
              {...register("password")}
              type="password"
              error={!!errors.password}
            />
            <p>{errors.password?.message}</p>
          </Box>
          <div><span onClick={() => navigate("/login")}>já tem conta?</span></div>
          <LoginButton type="submit">Entrar</LoginButton>
        </Container>
      </form>
    </LoginFormContainer>
    <Logo />
    </StyledBody>
  );
};

export default SignupComponent;
