import mail from "../../images/_avatar.jpg";
import Form from "./form";
import {
  StyledBlock, StyledContainer,
  StyledForm,
  StyledHeader, StyledText, StyledVector
} from "./style";
  
  const WithdrawComponent = () => {
    return (
      <StyledContainer>
        <StyledForm>
          <StyledHeader>
            <StyledBlock>
              <StyledVector src={mail}></StyledVector>
            </StyledBlock>
            <StyledText>
              <b>Solicitar Resgate</b>
            </StyledText>
          </StyledHeader>
          <Form />
        </StyledForm>
      </StyledContainer>
    );
  };
  
  export default WithdrawComponent;
  