import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from "react-router";
import AboutUs from '../AboutUs';
import Academy from '../Academy';
import Bank from '../Bank';
import Content from '../Content';
import Exchange from '../Exchange';
import Human from '../Human';
import Service from '../Service';
import Tech from '../Tech';
import "../style.css";
import "./style.css";

function Menu() {
  const navigate = useNavigate();
  return (
    <div className="mb-2">

        <DropdownButton
          drop={"end"}
          variant="secondary"
          title="│││"
        >
        <AboutUs end="end" />
         <Exchange end="end" />
         <Tech end="end" />
         <Bank  end="end" />
         <Human end="end" />
         <Academy end="end" /> 
         <Content end="end" />
         <Service end="end"/>
        </DropdownButton>

  </div>
//     <Dropdown>
//     <Dropdown.Toggle variant="success" id="dropdown-basic" drop="end" >
//       Sobre Nós
//     </Dropdown.Toggle>
//     <Dropdown.Menu>
//        <AboutUs />
//         <Exchange />
//         <Tech />
//         <Bank />
//         <Human />
//         <Academy /> 
//         <Content />
//         <Service />
//     </Dropdown.Menu>
//   </Dropdown>

  )
}

export default Menu;