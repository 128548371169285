import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import "../style.css";

function Content({end}) {
  const navigate = useNavigate()
  return (
    <Dropdown drop={end} >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Conteúdos
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => navigate("/signup")} >Tokenn Talks</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Palestras</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Blog</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Tokenn Ideals</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

  
  export default Content;