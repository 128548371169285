import styled from 'styled-components'

export const StyledContainer = styled.div`
display: flex;
justify-content: space-around;

@media (max-width: 1275px) {
    display: none;
  }

`

export const StyledMobileContainer = styled.div`
display: flex;
justify-content: space-around;
margin: auto auto auto 0px;

@media (min-width: 1274px) {
    display: none;
  }

`

export const StyledItem = styled.div`
width: 10%;
`
export const StyledH2 = styled.h4`
color: #39b54a;
`
export const StyledTokenn = styled.button`
color: white;
background-color: #39b54a;
width: 166px;
height: 30px;
font-size: 16px;

`
export const Styledwhite = styled.div`
color: white;
`
