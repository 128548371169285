// import Partner2 from "../../../images/logo_banklabs_final.png";
// import Partner1 from "../../../images/Mater_Market.png";
// import Partner3 from "../../../images/straittrust.png";
import Partner4 from "../../../images/WTB1_icon.png";
import { StyledDiv, StyledFooter, StyledImg, StyledItem, StyledItemLogo, StyledSpan, StyledemptyItem } from "./style";

function Copyright() {
  return (
    <StyledFooter>
      <StyledemptyItem></StyledemptyItem>
      <StyledItem>
      <StyledSpan >Copyright © 2023 Tokenn</StyledSpan>
      </StyledItem>
      <StyledItemLogo>
      <StyledImg alt="partner 4" src={Partner4} />
      <StyledDiv>by</StyledDiv>
      </StyledItemLogo>
      </StyledFooter>
  );
}

export default Copyright;


