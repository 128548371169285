import Copyright from './Compyright';
import Header from './Header';
// import logo from "../../images/logo_01.png";
import { StyledBody } from './style';

function HomeComponent() {
  return (
    <StyledBody>
        <Header />
        {/* <Partners /> */}
        {/* <CarouselComponent /> */}
        {/* <StyledImg src={logo} /> */}
        <Copyright />
    </StyledBody>
  );
}

export default HomeComponent;