import React from "react";
import { Route, Routes } from "react-router-dom";
import { Admin, Dashboard, GetInTouch, Home, Login, Signup, Withdraw } from "../Components";
import Intro from "../Components/Home/Footer/AboutUs/intro";
import Private from "../Components/Home/Footer/AboutUs/private/inde";
import Cambio from "../Components/Home/Footer/Exchange/Cambio";
import IndividualIntervalsExample from "../Components/Home/part";


const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/getintouch" element={<GetInTouch />} />
      <Route path="/withdraw" element={<Withdraw />} />
      <Route path="/test" element={<IndividualIntervalsExample />} />
      <Route path="/aboutus" element={<Intro />} />
      <Route path="/security" element={<Private />} />
      <Route path="/cambio" element={<Cambio />} />

    </Routes>

  );
};

export default Router;
