import api from "../../../services/api";
import {
  StyledButton, StyledContainer, StyledFlexDiv, StyledFoneInput,
  StyledMailField, StyledMailInput, StyledMessageField,
  StyledMessageInput, StyledNameField,
  StyledNameInput
} from "./style";
  
  const Form = () => {
    const createMessage = async () => {
      let name = document.querySelector("#nome");
      let email = document.querySelector("#mail");
      let telephone = document.querySelector("#fone");
      let message = document.querySelector("#message");
  
      let data = {
        name: name.value,
        email: email.value,
        telephone: telephone.value,
        message: message.value,
      };
  
      await api
        .post(`/messages/`, data)
        .then(() => {
          name.value = "";
          email.value = "";
          telephone.value = "";
          message.value = "";
        })
        .catch((e) => e.messages);
    };
  
    const save = () => {
      createMessage();
    };
    return (
      <StyledContainer>
        <StyledNameField>
          Seu Nome{" "}
          <StyledNameInput id="nome" placeholder="Digite seu nome..." />
        </StyledNameField>
        <StyledMailField>
          <StyledFlexDiv>
            Email* <StyledMailInput id="mail" placeholder="example@example.com" />
          </StyledFlexDiv>
          <StyledFlexDiv>
            valor* <StyledFoneInput id="fone" placeholder="RS XXX..." />
          </StyledFlexDiv>
        </StyledMailField>
        <StyledMessageField>
          observação
          <StyledMessageInput
            id="message"
            placeholder="Type what you want to say to us"
          />
        </StyledMessageField>
        <StyledButton onClick={() => save()}>Enviar</StyledButton>
      </StyledContainer>
    );
  };
  
  export default Form;
  