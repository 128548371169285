import { createSlice } from "@reduxjs/toolkit";

export const Slice = createSlice({
    name: 'user',
    initialState: {
        Token: '',
    },
    reducers: {
        login(state, { payload }){
            localStorage.setItem("Token", payload);
            return {...state, Token: payload}
        },
        logout(state){
            localStorage.clear()
            return {...state, Token: ''}
        }
    }
})

export const { login, logout } = Slice.actions

export const selectUser = state => state.user

export default Slice.reducer