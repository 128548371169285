import styled from 'styled-components';

export const StyledBody = styled.body`
background-color: rgb(241, 245, 249);
color: rgb(17, 24, 39);
display: flex;
flex: 1 1 auto;
width: 100%;
flex-direction: column;
margin: 0;
min-height: 100%;
position: relative;
font-feature-settings: "salt";
font-size: 14px;
line-height: 1.4;
overflow-x: hidden;
`

export const StyledRoot = styled.div`
height: 100%;
display: flex;
flex: 1 1 auto;
width: 100%;
`
export const StyledLayout = styled.div`
width: 100%;
display: flex;
`
export const StyledFlex = styled.div`
flex-direction: column;
flex: 1 1 auto;
min-width: 0;
display: flex;
`

export const StyledMain = styled.main`
min-width: 0;
display: flex;
position: relative;
flex-direction: column;
flex: 1 1 auto;

`
export const StyledHeader = styled.div`
--tw-shadow: 0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);
--tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -1px var(--tw-shadow-color);
box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow);
box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
width: 100%;
min-height: 3.5rem;
max-height: 3.5rem;
height: 3.5rem;
background-color: rgb(10, 42, 74);
color: rgb(255, 255, 255);
border-bottom-width: 5px;
border-bottom-color: rgb(195, 168, 83);
border-style: solid;
border-width: 0 0 5px 0;

`
export const StyledFlexHeader = styled.div`
padding: 0;
justify-content: space-between;
align-items: center;
flex: 1 1 auto;
width: 100%;
height: 100%;
display: flex;
z-index: 20;

@media (min-width: 1280px) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    max-width: 1280px;
  }

  @media (min-width: 960px) {
    max-width: 960px;
  }

  @media (min-width: 600px) {
    max-width: 600px;
  }  
`

export const StyledLogo = styled.div`
padding-left: .8rem;
padding-right: .8rem;
align-items: center;
flex-shrink: 0;
display: flex;
`
export const StyledFlexImage = styled.div`
align-items: center;
display: flex;
`
export const StyledImgLogo = styled.img`
height: 2.0rem;
max-width: 100%;
display: block;
vertical-align: middle;
`

export const StyledContent = styled.div`
flex-direction: column;
flex: 1 1 auto;
display: flex;
min-height: 0;
z-index: 10;
position: relative;
`
export const StyledGrid = styled.div`
background-color: rgb(0, 38, 74);
background-image: url("https://onboarding.ourinvest.com.br/assets/images/forms/selectFormBg.jpeg");
background-size: cover;
background-repeat: no-repeat;
padding: .5rem;
height: 100%;
box-sizing: border-box;
display: flex;
flex-flow: row wrap;
width: 100%;
justify-content: center;
align-items: center;
@media (max-width: 600px) {
    background-image: unset;
  }
`

export const StyledEmptyGrid = styled.div`
box-sizing: border-box;
margin: 0px;
flex-direction: row;
`

export const StyledInfo = styled.div`
flex-basis: 33.3333%;
flex-grow: 0;
max-width: 33.3333%;
box-sizing: border-box;
margin: 0px;

@media (max-width: 600px) {
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
  }  

`
export const StyledContainer = styled.div`
background-color: rgb(255, 255, 255);
transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
background-image: none;
border-radius: 16px;
padding: 8px;
text-align: center;
opacity: 0.9;
color: rgb(107, 114, 128);
font-size: 1.4rem;
font-family: Inter var, Roboto, "Helvetica", Arial, sans-serif;
font-weight: 400;
line-height: 1.43;
min-height: 150px;
`

export const StyledInsideContainer = styled.div`
display: inline-flex;
flex-direction: column;
position: relative;
min-width: 0px;
padding: 0px;
margin: 0px;
border: 0px none;
vertical-align: top;
`

export const StyledH6 = styled.h6`
margin: 0px;
font-family: Inter var, Roboto, "Helvetica", Arial, sans-serif;
font-weight: 400;
font-size: 1.2rem;
line-height: 1.75;
color: rgb(195, 168, 83);
text-align: center;
`
export const StyledH5 = styled.h5`
margin: 0px;
font-family: Inter var, Roboto, "Helvetica", Arial, sans-serif;
font-weight: 400;
font-size: 1.6rem;
line-height: 1.334;
color: rgb(10, 42, 74);
`
export const StyledButtons = styled.div`
box-sizing: border-box;
display: flex;
flex-flow: row wrap;
margin-top: 3.2rem;
width: calc(100% + 16px);
margin-left: -16px;
text-align: center;
color: rgb(107, 114, 128);
font-size: 1.4rem;
font-family: Inter var, Roboto, "Helvetica", Arial, sans-serif;
font-weight: 400;
line-height: 1.43;
`
export const StyledDivButton = styled.div`
padding-left: 16px;
padding-top: 16px;

@media (min-width: 1536px){
  flex-basis: 50%;
  flex-grow: 0;
  max-width: 50%;
}
  @media (min-width: 1200px){
  flex-basis: 50%;
  flex-grow: 0;
  max-width: 50%;
}
  @media (min-width: 900px){
  flex-basis: 50%;
  flex-grow: 0;
  max-width: 50%;
}
  @media (max-width: 600px){
  flex-basis: 100%;
  flex-grow: 0;
  max-width: 100%;
}

`

export const StyledButton = styled.button`
display: inline-flex;
align-items: center;
justify-content: center;
position: relative;
box-sizing: border-box;
outline: 0px;
border: 0px none;
margin: 0px;
cursor: pointer;
user-select: none;
vertical-align: middle;
appearance: none;
font-family: Inter var, Roboto, "Helvetica", Arial, sans-serif;
font-weight: 500;
font-size: 1.0rem;
line-height: 1.75;
min-width: 64px;
padding: 8px 22px;
transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
color: rgb(255, 255, 255);
background-color: rgb(10, 42, 74);
width: 100%;
text-transform: none;
border-radius: 28px;
`

export const StyledSpan = styled.span`
display: inherit;
margin-right: 8px;
margin-left: -4px;
`

export const StyledSvg = styled.svg`
user-select: none;
width: 1em;
height: 1em;
display: inline-block;
fill: currentcolor;
flex-shrink: 0;
transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
font-size: 22px;
`
export const StyledFooter = styled.header`
border-style: solid;
border-width: 5px 0 0 0;
border-top-color: rgb(195, 168, 83);
font-feature-settings: "salt";
font-size: 14px;
background-color: rgb(10, 42, 74);
color: white;
`

export const StyledFooterDiv = styled.div`
padding-bottom: 0;
padding-top: 0;
overflow-x: auto;
align-items: center;
display: flex;
width: 100%;
position: relative;
padding-left: .8rem;
padding-right: .8rem;
min-height: 4.8rem;



@media (min-width: 960px){
  min-height: 6.4rem;
}

@media (min-width: 600px){
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

@media (min-width: 960px) {
  max-width: 960px;
}

@media (min-width: 600px){
  max-width: 600px;
}

@media (min-width: 600px){
  min-height: 64px;
}
  @media (min-width: 0px) and (orientation: landscape){
  min-height: 48px;
}
  @media (min-width: 600px){
  padding-left: 24px;
  padding-right: 24px;
}

`