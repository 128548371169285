import { useState } from "react";
import { useNavigate } from "react-router";
import image1 from "../../../images/_avatar.jpg";
import api from "../../../services/api";
import { AdminWallet, StyledAdminBody, StyledAdminData, StyledAdminImg, StyledAdminItem, StyledInput, StyledItens } from "./style";

function UserEdit({user, Refresh}) {

  const [Permission, setPermission] = useState(user.permission);
  const [Mail, setMail] = useState(user.mail);
  const [Phone, setPhone] = useState(user.phone);
  const [Name, setName] = useState(user.name);
  const [Cnpj, setCnpj] = useState(user.cnpj);
  const [Money, setMoney] = useState(user.money);
  const [percent, setPercent] = useState(0);


  const navigate = useNavigate();


  function handleChangePermission(event) {
    setPermission(event.target.value);
    }

  function handleChangeMail(event) {
    setMail(event.target.value);
    }

  function handleChangePhone(event) {
  setPhone(event.target.value);
    }

  function handleChangeName(event) {
    setName(event.target.value);
    }

  function handleChangeCnpj(event) {
    setCnpj(event.target.value);
    }

  function handleChangeMoney(event) {
    setMoney(event.target.value);
    }

  function handleUpdate(event) {
    setPercent(event.target.value);
  }

  function handleSubmit(event) {

    user.permission = Permission;
    user.mail = Mail;
    user.name = Name;
    user.phone = Phone;
    user.cnpj= Cnpj;

    if(percent > 0){
      user.money = Money + (Money * (percent / 100) );
    }else {
      user.money = Money;
    }

      api
        .patch(`users/${user.id}`, user).then(() => {
          window.location.reload();
        })
        .catch((error) => {
          alert("Erro")
        });
    };


  return  (<>
    { user && 
    <StyledAdminBody>
      <div  >
    <AdminWallet>
        <div>
        <StyledAdminImg src={image1} />
        <StyledAdminItem defaultValue={user.mail} onChange={(event) => handleChangeMail(event)}/>
        <StyledAdminItem defaultValue={user.phone} onChange={(event) => handleChangePhone(event)}/>
        </div>
        <StyledAdminData>
          <StyledItens >
            <StyledAdminItem defaultValue={user.name} onChange={(event) => handleChangeName(event)}/>
            <StyledAdminItem defaultValue={user.cnpj} onChange={(event) => handleChangeCnpj(event)}/>
            <select defaultValue={user.permission} onChange={(event) => handleChangePermission(event)}>            
              <option value="0">Usuario</option>
              <option value="1">Investidor</option>
            </select> 
            <div>
            <StyledInput defaultValue={0} onChange={(event) => handleUpdate(event)}></StyledInput>
              <span>%</span>
            </div>
          </StyledItens >
          <StyledAdminItem defaultValue={user.money} onChange={(event) => handleChangeMoney(event)} />
          <button onClick={() => {handleSubmit() } } >Editar</button>
        </StyledAdminData>
      </AdminWallet>
      </div>
    </StyledAdminBody>
    }
    </>
  );
}

export default UserEdit;