import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import "../style.css";

function Exchange({end}) {
  const navigate = useNavigate();
    return (
      <Dropdown drop={end}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Exchange
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => navigate("/cambio")} >Câmbio</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Hedge</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Operações Estruturadas</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Remessas Internacionais</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >CDE - Conta de Domiciliado no Exterior</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Proteção Cambial</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Transferências Internacionais</Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/signup")} >Investimentos</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    );
  }
  
  export default Exchange;