import styled from 'styled-components'


export const StyledButton = styled.button`
color: white;
background-color: #39b54a;
width: 166px;
height: 35px;
border-radius: 5px;
border: 0;
font-size: 14px;
margin: 25px 20px;
min-width: 100px;

@media (max-width: 600px) {
  font-size: 13px;
}

@media (max-width: 600px) {
    margin: auto 5px;
  }
`

export const StyledDiv = styled.div`
display: flex;
justify-content: space-between;
background-color: white;
align-items: center;
`

export const StyledLogo = styled.div`
display: flex;
width: 33%;
flex-direction: row-reverse;
align-items: center;

@media (max-width: 500px) {
    width: 70%;
  }

`
export const StyledTokenn = styled.img`
width: 170px;
height: 50px;
font-size: 16px;
background-size: cover;
margin: auto;

@media (max-width: 1275px) {
  margin: 0;
}
@media (max-width: 600px) {
  display: none;
}
`
export const StyledTokennMobile = styled.img`
width: 60px;
height: 60px;
font-size: 16px;
background-size: cover;
margin: 5px 10px;

@media (min-width: 601px) {
  display: none;
}
`