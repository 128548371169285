import styled from "styled-components";
import image from "../../images/AdobeStock_ing06.jpeg";


export const StyledBody = styled.div`
  height: 100vh;
  background-image: url(${image });
  background-size: cover;
  

`;


export const StyledImg = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 20px;
  margin: 2px;
`;
